.content {
  padding: 50px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .addButtonWrapper {
    display: flex;
    justify-content: flex-start;

    & > .button {
      padding: 0 var(--offset-md) 0 var(--offset-xx-sm);
      border-radius: 0 25px 25px 0;
    }

    & > .plusIcon {
      background-color: var(--darker-pink);
      border-radius: 25px 0 0 25px;
      width: 50px;
      height: 50px;

      & > span {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        font-size: 28px;
      }
    }

    &:hover {
      & > .plusIcon {
        background-color: var(--pink);
      }

      & > .button {
        background-color: var(--light-pink);
      }
    }
  }

  .inputWrapper {
    margin-top: var(--offset-xxx-lg);
  }

  .helpText {
    margin: var(--offset-x-sm) 0 var(--offset-md) 0;
  }
}
