@import '_scss-variables.scss';

.container {
  padding: var(--offset-x-sm) var(--offset-sm);

  .headerLimiter {
    display: grid;
    grid-template-columns: [start] repeat(auto-fill, 330px) [end];
    max-width: 1200px;
    grid-gap: var(--offset-xx-sm);

    margin-top: var(--offset-md);

    @media only screen and (max-width: 1024px) {
      grid-template-columns: [start] repeat(auto-fill, 320px) [end];
    }

    .labelContainer {
      grid-column: start / end;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .studentGroupTable {
    grid-column: start / end;
    max-width: 1010px;

    > table > tbody > tr:last-child td {
      border-bottom: 0;
    }
  }

  .noResultsContainer {
    padding: var(--offset-x-sm);
    max-width: 1010px;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin-bottom: var(--offset-x-sm);
}
