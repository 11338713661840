.cardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 330px);
  grid-gap: var(--offset-xx-sm);
  margin-top: var(--offset-x-sm);
}
/* Tablet Landscape and below */
@media only screen and (max-width: 1024px) {
  .cardsContainer {
    grid-template-columns: repeat(auto-fill, 320px);
  }
}

.studentGroupCardSkeleton {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: var(--offset-sm);
}

.content > * {
  margin: var(--offset-sm);
}
