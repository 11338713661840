.completeAllBtn {
  width: 130px;
}

.noSessionsCell {
  padding: var(--offset-x-sm);
  width: 100%;
  text-align: center;
}

.tableHeader {
  border-bottom: 1px solid var(--new-grey5);
  padding: var(--offset-xx-sm) var(--offset-sm);
}

.tableRow {
  text-decoration: none;
  padding: var(--offset-xx-sm) var(--offset-sm);
  border-bottom: 1px solid var(--new-grey6);
}

.tableRow > *:not(:last-child),
.tableHeader > *:not(:last-child) {
  padding-right: 8px;
}

.tableRow > *:not(:first-child),
.tableHeader > *:not(:first-child) {
  padding-left: 8px;
}

.paginationCell {
  margin: 0 auto;
}

.pagination {
  padding: var(--offset-xx-sm);
}

.pagination > ul {
  justify-content: center;
}

.completeButton {
  padding: 0;
  transition: color 0.2s linear;
}

.completeButton:hover {
  color: var(--green);
  background-color: white;
}
