@import '_scss-variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: var(--offset-lg);

  .title {
    margin-bottom: var(--offset-lg);
  }

  .search {
    width: 330px;
  }

  .divider {
    position: relative;

    color: var(--new-grey5);

    margin: var(--offset-md) 0;

    &:before {
      width: 81px;

      content: '';

      top: 15px;
      right: 25px;
      position: absolute;

      border-bottom: 1px solid var(--new-grey5);
    }

    &:after {
      width: 81px;

      content: '';

      top: 15px;
      left: 25px;
      position: absolute;

      border-bottom: 1px solid var(--new-grey5);
    }
  }

  .buttonGroup {
    width: 330px;
    height: 40px;

    .addIconButton {
      background-color: var(--new-grey4);
    }

    .createButton {
      width: 100%;
      background-color: var(--new-grey5);
    }

    .label {
      color: var(--new-grey1);
    }
  }

  .recentAssignments {
    margin-top: var(--offset-lg);

    .assignmentItem {
      display: flex;
      align-items: center;

      padding: var(--offset-xxx-sm) var(--offset-xx-sm);
      margin-top: var(--offset-xx-sm);

      width: 330px;
      min-height: 32px;

      background: var(--new-grey6);
      border-radius: 5px;

      cursor: pointer;
      transition: background-color 0.3s linear;

      &:hover {
        background-color: var(--new-grey5);
      }
    }
  }
}
