.popup {
  padding: var(--offset-xx-sm);
  display: grid;
  grid-gap: var(--offset-xx-sm);

  .inputField {
    height: 115px;
    background-color: var(--new-grey6);

    > div {
      display: flex;
      align-items: flex-start;
    }

    textarea {
      max-height: 100%;
    }
  }

  .emojiButton {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
