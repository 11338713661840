.noMarginPaper {
  margin: 0;
  border-top: none;
}
.smallMarginPaper {
  margin: var(--offset-x-sm) 170px var(--offset-x-sm) var(--offset-sm);
}
.bigMarginPaper {
  margin: var(--offset-x-sm) 170px;
}

.paperHeading {
  padding: var(--offset-md) var(--offset-x-lg) var(--offset-md) var(--offset-sm);
}

.paperSection {
  padding: var(--offset-md) var(--offset-xxx-lg);
}

.assignedSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trashIcon {
  width: 16px;
  height: 16px;
}
.archiveAllButton {
  margin-right: var(--offset-sm);
}

.activityFilters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--offset-sm);
}

.activityButton {
  background-color: var(--light-grey);
  color: var(--dark-grey) !important;
  border-radius: 10px;
  border-color: var(--grey-1);
}

.activityButton:hover,
.actionTimeSelect:hover {
  border-color: var(--grey-1);
}

.actionTimeSelect {
  background-color: white;
  color: var(--dark-grey);
  border-radius: 10px;
  border-color: var(--grey-1);
}

.activeActivity {
  background-color: var(--light-blue);
  color: white !important;
}
