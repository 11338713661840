.activityPageWrapper {
  margin: var(--offset-md);
}

.tableContent {
  padding: var(--offset-lg);
}

.timespanHeader {
  color: var(--grey-3);
}

.pagination {
  padding: var(--offset-md);
}

.pagination > ul {
  justify-content: center;
}

.paddedRight {
  margin-right: 220px;
}

.tabletActivityTable {
  border-right: 0px !important;
  border-left: 0px !important;
  border-radius: 0px !important;
}
