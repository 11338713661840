.container {
  display: flex;
  align-items: center;
  flex-direction: column;

  .header {
    color: var(--new-blue1);

    margin-top: var(--offset-lg);
    margin-bottom: var(--offset-md);
  }

  .upload {
    width: 569px;
    height: 283px;

    padding: var(--offset-sm);

    align-items: center;
    display: flex;
    justify-content: center;

    background-color: var(--new-grey7);
    border: 1px solid var(--new-grey5);
    border-radius: 5px;

    cursor: pointer;

    .uploadInnerContainer {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;

      border: 2px dashed var(--new-grey5);
      border-radius: 2px;

      .uploadIcon {
        margin-bottom: var(--offset-sm);
      }

      .uploadText {
        padding-bottom: var(--offset-xx-sm);
      }
    }
  }
}
