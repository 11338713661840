.dialog {
  width: 100%;
  max-width: 620px;

  .header {
    border-radius: inherit;
    padding: var(--offset-x-lg) var(--offset-x-lg) 0 var(--offset-x-lg);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button {
      width: 125px;

      &:last-child {
        margin-left: var(--offset-sm);
      }
    }
  }

  .dialogContent {
    padding: var(--offset-lg) var(--offset-x-lg);

    .source {
      width: 177px;
      height: 24px;

      padding: var(--offset-xxx-sm) var(--offset-xx-sm);
      margin: var(--offset-lg) 0;

      font-weight: 600;

      background-color: var(--new-blue6);
      border-radius: 5px;
    }

    .label {
      margin: var(--offset-lg) 0px var(--offset-xxx-sm) 0px;
    }

    .rostersInformation {
      height: 40px;

      padding: var(--offset-xx-sm);

      background-color: var(--new-grey7);
      border-radius: 5px;
    }

    .selectedUsers {
      margin-top: var(--offset-sm);

      .selectedUserRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: var(--offset-xx-sm);

        &:last-child {
          margin-bottom: 0;
        }

        .minusButton {
          width: 24px;
          min-width: 24px;
        }
      }
    }

    .progress {
      margin-top: var(--offset-sm);
    }
  }
}

.progress {
  margin-top: var(--offset-sm);
}
