.tooltip {
  background-color: var(--new-blue1);
  max-width: 100%;
  padding: 0;
}
.popper {
  pointer-events: all;
  position: fixed !important;
  top: 80px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
}

.tab {
  display: flex;
  background-color: var(--new-blue1);
  padding: 20px;
  cursor: pointer;
  box-sizing: border-box;
}
.tab:last-child {
  border-left: 1px solid var(--new-blue3);
  border-radius: 0 5px 5px 0;
}
.tab:first-child {
  border-radius: 5px 0 0 5px;
}
.tab.disabled {
  cursor: not-allowed;
}

.tab:hover:not(.disabled) {
  opacity: 0.85;
  transition: opacity 0.2s;
}
