.dialogHeader {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  display: grid;
  align-items: center;
  grid-gap: var(--offset-sm);
  grid-template-columns: 125px 1fr 125px;
}

.dialogContent {
  overflow-y: unset;
}
