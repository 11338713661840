@import '_scss-variables.scss';

.wrapper {
  top: 0;
  left: 0;
  position: fixed;

  width: 100vw;
  height: 100vh;
  z-index: 1201; // The sidebar has 1200.

  overflow-x: auto;

  background-color: var(--white);

  padding: var(--offset-x-lg);

  @media screen and (max-width: $max-tablet-screen) {
    padding: var(--offset-sm);
  }

  .header {
    display: grid;
    grid-gap: 70px;
    grid-template-columns: 220px 1fr 110px 40px; // 220 is 110(second to last column) + 40(last column) + 70(gap between the 2 columns)

    align-items: center;

    @media screen and (max-width: $max-tablet-screen) {
      grid-gap: var(--offset-sm);
      grid-template-columns: 107px 1fr 107px;

      grid-template-areas:
        '. . close'
        '. stepper next';
    }

    .stepper {
      grid-column-start: 2;

      @media screen and (max-width: $max-tablet-screen) {
        grid-area: stepper;
      }
    }

    .nextButton {
      height: 40px;
      width: 110px;
      margin-right: 80px;

      @media screen and (max-width: $max-tablet-screen) {
        grid-area: next;

        margin-right: unset;
        margin-left: unset;
      }
    }

    .close {
      color: var(--new-grey1);

      width: 40px;
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      justify-self: flex-end;

      @media screen and (max-width: $max-tablet-screen) {
        grid-area: close;
      }
    }

    .disabledClose {
      cursor: not-allowed;
    }
  }

  .loadingOverlay {
    top: 0;
    left: 0;
    position: fixed;

    width: 100%;
    height: 100%;
    z-index: 2;

    background-color: rgba(255, 255, 255, 0.3);

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: wait;

    animation: fadeIn 0.2s linear;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    &:after {
      content: '';
      width: 60px;
      height: 60px;

      border-radius: 50%;
      border: 6px solid var(--new-blue5);
      border-bottom-color: var(--new-blue4);

      animation: spin 0.6s linear infinite;

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(359deg);
        }
      }
    }
  }
}
