.dialog {
  width: 100%;
  max-width: 700px;
}

.dialogHeader {
  border-radius: inherit;
  padding: var(--offset-x-lg) var(--offset-x-lg) 0 var(--offset-x-lg);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogButton {
  width: 125px;
}
.dialogButton:last-child {
  margin-left: var(--offset-sm);
}

.dialogContent {
  padding: var(--offset-lg) var(--offset-x-lg);
}

.label {
  margin: var(--offset-lg) 0px var(--offset-xxx-sm) 0px;
}

.searchField {
  padding-right: 0;
}

.searchField input {
  margin-left: 10px;
}

.useTutorButton {
  color: var(--new-blue1);
  background-color: var(--new-blue6);
}
.useTutorButton:hover {
  background-color: var(--new-blue5);
}

.notesForTutor {
  height: 100%;
  margin: var(--offset-lg) 0px var(--offset-xxx-sm) 0px;
}
.textarea {
  /* Imitating Typography B-Text-2 */
  font-size: 15px;
  font-weight: 400;
}

.studentCommentsContainer > * + * {
  margin-top: var(--offset-sm);
}

.studentCommentRow {
  display: grid;
  grid-template-columns: 0.6fr 1.4fr;
  grid-gap: var(--offset-sm);
  align-items: center;
}

.progress {
  margin-top: var(--offset-sm);
}
