.container {
  display: flex;
  align-items: center;
  justify-content: center;

  .step {
    display: flex;
    align-items: center;

    & + .step {
      margin-left: var(--offset-xxx-sm);
    }

    .prevStepConnector {
      width: 16px;
      height: 4px;
      background-color: var(--new-grey4);
    }

    .iconContainer {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--new-grey4);

      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: var(--offset-xxx-sm);
    }

    .name {
      font-weight: 600;
      color: var(--new-grey3);
    }

    &.completed {
      .prevStepConnector,
      .iconContainer {
        background-color: var(--pink-4);
      }

      .name {
        color: var(--pink-4);
      }
    }

    &.active {
      .prevStepConnector,
      .iconContainer {
        background-color: var(--pink-1);
      }
    }
  }
}
