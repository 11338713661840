.filtersWrapper {
  display: grid;
  grid-template-columns: 180px;
  grid-gap: var(--offset-sm);
  margin-bottom: var(--offset-x-sm);
}

.filterField {
  margin-top: var(--offset-xxx-sm);
}
