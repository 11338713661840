.container {
  margin-top: var(--offset-sm);
  margin-bottom: var(--offset-lg);

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--offset-xx-sm);

    &:last-child {
      margin-bottom: 0;
    }

    .extraInformation {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .studentId {
        width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
