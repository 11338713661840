.link {
  text-decoration: none;
}

.copyTasksButton {
  min-width: 125px;
}

.tasksContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--offset-xx-sm);
  margin-top: var(--offset-xx-sm);
  align-items: flex-start;

  /* Tablet Landscape and below */
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--offset-sm);
  }
}
