@import '_scss-variables.scss';

.filtersAndTabsContainer {
  margin: var(--offset-sm) var(--offset-sm) 0 var(--offset-sm);
  max-width: 1100px;

  @media screen and (max-width: $max-tablet-screen) {
    margin: 0;
    max-width: 100%;
  }

  .filters {
    position: relative;
  }
}

.viewContainer {
  overflow-x: hidden;

  .standardsViewContainer {
    margin: var(--offset-xx-sm) var(--offset-sm) 0 var(--offset-sm);
    max-width: 1100px;

    @media screen and (max-width: $max-tablet-screen) {
      margin: var(--offset-xx-sm) 0 0 0;
      max-width: 100%;
    }
  }

  .incompleteAssignmentsContainer {
    margin: var(--offset-sm) var(--offset-sm) var(--offset-xx-sm)
      var(--offset-sm);
    max-width: 1100px;

    @media screen and (max-width: $max-tablet-screen) {
      margin: 0;
      max-width: 100%;
    }
  }
}
