.assignmentName {
  margin-top: var(--offset-lg);

  text-align: center;
}

.pagesNumber {
  margin-top: var(--offset-xx-sm);

  text-align: center;
}

.itemsContainer {
  display: grid;
  grid-gap: var(--offset-sm);
  grid-template-columns: repeat(auto-fit, 200px);

  margin-top: var(--offset-sm);

  align-items: center;
  justify-content: center;

  .assignmentItem {
    overflow: hidden;
    border-radius: 4px 4px 5px 5px;
    border: 1px solid var(--new-grey5);

    .image {
      width: 100%;
      height: 244px;
      background-size: cover;
      background-position: center;
    }

    .nameContainer {
      .taskName {
        padding: var(--offset-xx-sm) 0;
        text-align: center;
      }
    }
  }
}

.rotateButtonContainer {
  display: flex;
  justify-content: center;

  margin-top: var(--offset-lg);

  .rotateButton {
    color: var(--new-grey1);

    display: flex;
    align-items: center;

    .rotateIcon {
      color: inherit;
      font-size: 20px;
      margin-right: var(--offset-xxx-sm);
    }
  }
}

.tipsContainer {
  display: flex;
  justify-content: center;

  margin-top: var(--offset-lg);

  .tips {
    width: 640px;

    .tipsHeader {
      display: flex;
      align-items: center;
    }

    .tipsContent {
      margin-top: 5px;
    }

    .tipsContent > li {
      line-height: 15px;

      > p {
        font-weight: 600;
        color: var(--new-grey1);
      }
    }
  }
}
