@import '_scss-variables.scss';

.container {
  padding: var(--offset-sm) var(--offset-sm) var(--offset-xx-lg)
    var(--offset-sm);
}

.skeletonContainer {
  margin: var(--offset-sm);
  max-width: 1010px;
}

.headerContainer {
  grid-column-gap: var(--offset-xx-sm);
  max-width: 1010px;
}

.headerContainer > .assignmentHeader {
  grid-column: start / end;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.commentsContainer {
  grid-column: start / end;
}

.comments.comments {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: start / end;
  padding: var(--offset-sm);
}
.header .actionButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .actionButtons > * {
  white-space: nowrap;
}

.header .actionButtons > * + * {
  margin-left: var(--offset-lg);

  @media screen and (max-width: $max-tablet-screen) {
    margin-left: var(--offset-sm);
  }
}

.header .actionButtons button + button {
  @media screen and (max-width: $max-tablet-screen) {
    padding: var(--offset-xx-sm);
  }
}

.feedContainer {
  grid-gap: var(--offset-xx-sm);
  max-width: 1010px;
}

.feed {
  grid-column: start / end;
}

.assignmentInfo {
  padding: var(--offset-sm);
}

.flexRowContainer {
  display: grid;
  grid-template-columns: 100px 300px 1fr;
  grid-gap: var(--offset-sm);
  margin-top: 15px;
  margin-bottom: var(--offset-x-sm);
}

.progressBarContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.progressBarContainer > * + * {
  margin-left: var(--offset-xx-sm);
}

.attachment {
  display: inline-flex;
  align-items: flex-end;
  margin-top: var(--offset-xx-sm);
}

.attachmentName {
  margin-left: var(--offset-xx-sm);
}

.assignmentNotes {
  padding: var(--offset-sm);
}

.comment {
  margin-top: var(--offset-x-sm);
}

.assignmentBlock + .assignmentBlock {
  margin-top: var(--offset-sm);
}

.assignmentDescription {
  /* Make sure if link is placed in description, it does not go out of container */
  overflow-wrap: break-word;
}

@keyframes expandVertically {
  from {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
  }
  to {
    max-height: 600px;
    overflow: visible;
    opacity: 1;
  }
}

.loadingPlaceholder {
  margin-top: var(--offset-x-sm);
}
