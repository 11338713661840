.activityRow {
  padding: var(--offset-xxx-sm) 0;
}

.activityIcon {
  color: var(--blue);
  margin-right: var(--offset-xxx-sm);
}

.edlightBlue {
  color: var(--blue);
}
