.filtersWrapper {
  max-width: 1010px;
  padding: var(--offset-x-sm) 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 16px;
}

@media only screen and (max-width: 1070px) {
  .filtersWrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

@media only screen and (max-width: 724px) {
  .filtersWrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
