.dialog {
  width: 100%;
  max-width: 620px;
}

.dialogHeader {
  border-radius: inherit;
  padding: var(--offset-x-lg) var(--offset-x-lg) 0 var(--offset-x-lg);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogButton {
  width: 125px;
}
.dialogButton:last-child {
  margin-left: var(--offset-sm);
}

.dialogContent {
  padding: var(--offset-lg) var(--offset-x-lg);
}

.label {
  margin: var(--offset-lg) 0px var(--offset-xxx-sm) 0px;
}

.schoolsSelect {
  margin-bottom: var(--offset-lg);
}

.selectedStudents {
  margin-top: var(--offset-sm);
  margin-bottom: var(--offset-lg);
}
.selectedStudentRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--offset-xx-sm);
}
.selectedStudentRow:last-child {
  margin-bottom: 0;
}
.selectedStudentExtraInfo {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selectedStudentId {
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.minusButton {
  width: 24px;
  min-width: 24px;
}

.selectedUsers {
  margin-top: var(--offset-sm);
}
.selectedUserRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--offset-xx-sm);
}
.selectedUserRow:last-child {
  margin-bottom: 0;
}

.progress {
  margin-top: var(--offset-sm);
}
