.tutoringFeedSkeletonWrapper {
  position: relative;
}

.container {
  padding: var(--offset-sm);
}

.headerContainer {
  grid-template-columns: [start] repeat(auto-fill, 330px) [end];
  grid-column-gap: var(--offset-xx-sm);
}

.header.header {
  padding: var(--offset-sm);
  grid-column: start / end;
}

.sessionTitle {
  margin-right: var(--offset-sm);
}

.header .actionButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .actionButtons > * + * {
  margin-left: var(--offset-lg);
}

.assignmentHeader {
  display: flex;
  justify-content: space-between;
}

.notes {
  display: flex;
}

.notesContent {
  width: 100%;
  margin-left: var(--offset-xx-sm);
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 330px);
  grid-column-gap: var(--offset-xx-sm);
}

.cardContent {
  padding: 0;
  padding-bottom: 0 !important; /* Override mui card default padding*/
}

.contentSection {
  padding: var(--offset-x-sm);
}

.flexWithSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.animationStep {
  margin: var(--offset-sm) 0;
  position: absolute;
  width: 100%;
}

.animationFirstStep {
  animation: firstStepTransition;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
}
@keyframes firstStepTransition {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  67% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animationSecondStep {
  animation: secondStepTransition;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
}
@keyframes secondStepTransition {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  67% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.animationThirdStep {
  animation: thirdStepTransition;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
}
@keyframes thirdStepTransition {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  67% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Tablet Landscape and below */
@media only screen and (max-width: 1024px) {
  .headerContainer {
    grid-template-columns: [start] repeat(auto-fill, 320px) [end];
  }
  .cardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, 320px);
    grid-column-gap: var(--offset-xx-sm);
  }
}
