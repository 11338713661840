@import '_scss-variables.scss';

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 330px);
  grid-gap: var(--offset-xx-sm);
  margin-top: var(--offset-x-sm);

  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, 320px);
  }
}
