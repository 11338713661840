.studentGroupCard {
  display: flex;
  flex-direction: column;

  cursor: pointer;

  &:hover .editIcon {
    opacity: 1;
  }

  .header {
    padding: var(--offset-sm);
    display: flex;
    justify-content: space-between;
    align-items: center;

    min-height: 70px;
  }

  .editIcon {
    opacity: 0;

    transition: opacity 0.3s linear;
  }

  .content {
    padding: var(--offset-sm);
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .studentsNames {
      margin-top: var(--offset-xxx-sm);
    }

    .countWrapper {
      margin-top: var(--offset-x-sm);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
