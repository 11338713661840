.activityRow {
  margin: var(--offset-sm) 0 var(--offset-sm) var(--offset-x-sm);
  width: 100%;
}

.activityLink {
  color: var(--grey-darker) !important;
  text-decoration: none;
}

.activityIcon {
  color: var(--blue);
  margin-right: var(--offset-xxx-sm);
}

.noActivityWrapper {
  text-align: center;
  position: relative;
  width: 100%;
}

.noActivityTitle {
  position: absolute;
  top: 22%;
  left: 50%;
  transform: translate(-50%, 0%);
}
