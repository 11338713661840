.dialog {
  width: 350px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: var(--offset-x-lg) 60px;
}
.icon {
  width: 85px;
  height: 85px;
  margin-bottom: var(--offset-lg);
}
.title {
  margin-bottom: var(--offset-xx-sm);
}

.resourceHubContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--offset-sm);
}

.infoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--offset-sm);
  width: 100%;
}
.infoWrapper:last-child {
  margin-bottom: 0;
}
.infoWrapper > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: var(--offset-xx-sm);
}
